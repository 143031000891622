/* eslint-disable new-cap */
import vue from 'vue'
import vueRouter from 'vue-router'

vue.use(vueRouter)

const router = new vueRouter(
  {
    mode: 'history',
    routes: [
      {
        path: '',
        component: () => import('@/pages/index')
      },
      {
        path: '/table',
        component: () => import('@/pages/table')
      },
      {
        path: '/watch',
        component: () => import('@/pages/watch')
      }
    ]
  }
)

export default router
