import Vue from 'vue'
import App from './App.vue'
import Router from './router/index'
// import { Button, Pagination, Table } from 'ant-design-vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import scroll from 'vue-seamless-scroll'
// import { DatePicker } from 'ant-design-vue'

Vue.config.productionTip = false
// Vue.use(Button, Pagination, Table)
Vue.use(Antd, scroll)

new Vue({
  render: h => h(App),
  router: Router
}).$mount('#app')
